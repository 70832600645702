(function () {
    angular.module('informaApp')
        .component('logoutRedirect', {
            controller: LogoutRedirectController,
            templateUrl: 'components/logout/logout-redirect/template.html'
        });

    function LogoutRedirectController(AuthenticationService, $window, OmnitureSvc, Backdrop, NavigatorService) {
        if (!AuthenticationService.isLoggedIn()) {
            NavigatorService.openLogout();
            return;
        } 

        Backdrop.show();
        
        // trackLogout(AuthenticationService.getCurrentUser(), OmnitureSvc);

        AuthenticationService.generateLogoutUrl().then(url => {
            AuthenticationService.logout();
            Backdrop.hide();

            $window.location.href = url;
        });
    }

    function trackLogout(user, OmnitureSvc) {
        if (!user.hasAccess) {
            return;
        }

        OmnitureSvc.trackLogout();
    }
})();
